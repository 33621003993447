.container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  flex: 1;
  z-index: -100;
  transition: opacity 2s ease-out;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
