@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  animation: fadein 1s forwards;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 10px;
  color: black;
  flex: 1;
  display: grid;
  grid-template-areas: 'main';
  grid-gap: 10px;
  grid-template-rows: [main] 1fr;
  z-index: 1;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-area: main;
  align-items: center;
  justify-content: center;
}

.heading {
  font-family: 'Libre Caslon Display', serif;
}
