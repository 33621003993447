@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 20px;
  flex: 1;
  display: flex;
  z-index: 1;
  justify-content: center;
  animation: fade 0.8s backwards;
  animation-delay: 0.5s;
  align-items: center;
}
