@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  transition: max-height 0.5s ease-out;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 85%;
}

.blinking {
  animation: blink 0.7s infinite;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cursor {
  opacity: 1;
  margin-left: 1px;
}

.hide {
  animation: hide 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
