.container {
  display: inline-block;
}

.letters {
  font-family: 'Libre Caslon Display', serif;
  font-weight: 100;
  font-size: 120%;
  letter-spacing: 0.3em;
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding-bottom: 2px;
}

.letter {
  display: inline-block;
  line-height: 1em;
}

@mixin line {
  position: absolute;
  left: 0;
  height: 1px;
  width: calc(100% - 6px);
  transform-origin: 0% 100%;
  bottom: 0;
}

.underline {
  @include line();
  background-color: #999;
  opacity: 0;
}

.loadingIndicator {
  @include line();
  opacity: 1;
  background-color: #000;
  z-index: 1;
}
