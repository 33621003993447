@keyframes fadeslide {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.socials {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  li {
    font-size: 120%;
    display: inline;
    margin: 10px;
    animation: fadeslide 800ms backwards;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation-delay: 200ms + $i * 200ms;
      }
    }

    a {
      color: black;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #777;
      }

      span {
        margin-top: -5px;
        font-size: 60%;
        color: #555;
      }
    }
  }
}
