$scale: 1.5;

.logo {
  padding: 10px;
  letter-spacing: 5px * $scale;
  font-family: 'Libre Caslon Display', serif;
  font-size: 100% * $scale;
}

.row {
  line-height: 20px * $scale;
}
