.foreground {
  z-index: 100000;
  pointer-events: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  flex: 1;
}

.noise {
  position: absolute;
  background-image: url(../images/noise.gif);
  background-repeat: repeat;
  mix-blend-mode: exclusion;
  opacity: 0.08;
  width: 100%;
  height: 100%;
}
